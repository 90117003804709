import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "pages/Home";
import PrivacyPolicy from "pages/PrivacyPolicy";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConf } from "utilities/notify";

const App = () => {
  return (
    <>
    <ToastContainer {...toastConf} />
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
    </>
  );
};

export default App;
