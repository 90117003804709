import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { contactUs } from "actions"; // Ensure the path to contactUs is correct
import { notify } from "utilities/notify"; // Import notify for user feedback

const ContactUsForm = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const form = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const [hover, setHover] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");

    if (!validateEmail(email)) {
      notify("Please enter a valid email address", "error");
      return;
    }

    setIsSending(true);

    const data = { name, email, message };

    try {
      const response = await contactUs(data);
      if (response) {
        notify("Message received! We'll be in touch soon.", "success");
        form.current.reset(); // Clear the form after successful submission
      }
    } catch (error) {
      console.error("Error in contact form submission:", error);
      notify("There was an error submitting the form", "error");
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    if (buttonClicked) {
      const timer = setTimeout(() => {
        setButtonClicked(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [buttonClicked]);

  return (
    <Form
      ref={form}
      onSubmit={onSubmit}
      className="d-flex flex-column align-items-stretch"
      style={{ width: medium ? "580px" : "" }}
    >
      <Form.Group
        className="mb-4 d-flex align-items-center justify-content-between"
        style={{
          flexDirection: !medium ? "column" : "",
          marginTop: medium ? "" : "36px",
        }}
      >
        <Form.Label className={`lato ${medium ? "font-md" : "font-xxs"}`}>
          Name
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          required
          className="text-white"
          style={{
            width: !medium ? "237.5px" : "380px",
            height: !medium ? "25px" : "40px",
            backgroundColor: "transparent",
            borderColor: "1px solid #ECECEC",
            borderRadius: !medium ? "9.38px" : "15px",
          }}
        />
      </Form.Group>

      <Form.Group
        className="mb-4 d-flex align-items-center justify-content-between"
        style={{
          flexDirection: !medium ? "column" : "",
          marginTop: medium ? "" : "36px",
        }}
      >
        <Form.Label className={`lato ${medium ? "font-md" : "font-xxs"}`}>
          Email
        </Form.Label>
        <Form.Control
          type="email"
          name="email"
          required
          className=" text-white"
          style={{
            width: !medium ? "237.5px" : "380px",
            height: !medium ? "25px" : "40px",
            backgroundColor: "transparent",
            borderColor: "1px solid #ECECEC",
            borderRadius: !medium ? "9.38px" : "15px",
          }}
        />
      </Form.Group>

      <Form.Group
        className="d-flex align-items-center justify-content-between"
        style={{
          flexDirection: !medium ? "column" : "",
          marginTop: medium ? "" : "36px",
        }}
      >
        <Form.Label className={`lato ${medium ? "font-md" : "font-xxs"}`}>
          Message
        </Form.Label>
        <Form.Control
          as="textarea"
          name="message"
          required
          className=" text-white"
          style={{
            width: !medium ? "237.5px" : "380px",
            height: medium ? "100px" : "60px",
            backgroundColor: "transparent",
            borderColor: "1px solid #ECECEC",
            borderRadius: !medium ? "9.38px" : "15px",
          }}
        />
      </Form.Group>

      <div
        className={`d-flex ${
          !medium ? "justify-content-around" : "justify-content-end mt-5"
        }`}
        style={{
          marginTop: medium ? "" : "36px",
        }}
      >
        <Button
          className={`px-5 py-2 py-lg-3 position-relative lato font-bold ${
            medium ? "font-md" : "font-xxs"
          }`}
          type="submit"
          disabled={isSending}
          style={{
            width: medium ? "314px" : "181.66px",
            height: medium ? "64px" : "37.5px",
            marginTop: medium ? "" : "58px",
            border: "none",
            borderRadius: 0,
            background: medium
              ? "linear-gradient(90deg, #1f1715 0%, #272727 100%)"
              : "linear-gradient(90deg, rgba(179, 167, 167, 0.48) 0%, rgba(255, 255, 255, 0.48) 100%)",
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setButtonClicked(true)}
        >
          {hover && (
            <div
              className={`position-absolute cursor-pointer ${
                buttonClicked
                  ? "button-clicked-interaction"
                  : "button-hover-interaction"
              }`}
              style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: buttonClicked
                  ? "rgba(0, 0, 0, 0)"
                  : "rgba(0, 0, 0, 0.3)",
              }}
            ></div>
          )}
          <span
            className=" bebas-neue"
            style={{
              textShadow: "2px 8px 6px rgba(0, 0, 0, 0.9)",
            }}
          >
            {isSending ? (
              <span>
                <Spinner size="sm" />
                <span className="ms-2">SENDING...</span>
              </span>
            ) : (
              <span className="lato">Send</span>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default ContactUsForm;
